import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  CoveragesDeductibles,
  PreExistingConditionToggle,
  PlanCard,
  Spinner,
  GoBack,
  PlanComparison,
  TugoExtraOptions,
} from "../components"
import {
  getCurrentPath,
  setAllData,
  quoteActionsToDispatch,
  clearQuotes,
  clearComparisonInsurer,
  setQuotesLoaded,
  setCoverage,
  setDeductible,
  setUnavailableCoverages,
  setUnavailableDeductibles,
  insurerAvailAction,
  preExistingConditionAction,
} from "../redux/actions"
import ACTIONS from "../redux/actions/actionTypes"
import useInsurersLogos from "../hooks/use-insurers-logos"
import { mq } from "../utils/media-query"
import {
  getCoverages,
  getDeductibles,
  validTravellers,
  coverageDeductibleCombo,
  setPlanCardData,
  setTravelPlanPayload,
  setPayloadForQuote,
  ifAllQuotesLoaded,
  isCompareInsurers,
  travellerOver80,
  getUnavailableCoveragesOrDeductibles,
  checkExtraSportCoverage,
  checkInsuerAvailability,
} from "../utils/helpers"
import { lessThanOneMonth } from "../utils/time-related-func"

const GetQuotePolicies = ({
  location,
  getCurrentPath,
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  compareInsurer,
  unavailableCoverages,
  unavailableDeductibles,
  insurerAvail,
}) => {
  typeof window !== "undefined" &&
    window.gtag("event", "conversion", {
      send_to: "AW-349305909/HXL0CLfSp8cCELX4x6YB",
    })
  const intl = useIntl()
  const dispatch = useDispatch()
  const preExisting = useSelector(state => state.preExisting)
  const allQuotesLoaded = useSelector(state => state.allQuotesLoaded)
  const [coverages, setCoverages] = useState([])
  const [deductibles, setDeductibles] = useState([])
  const [isValidTravellers, setIsValidTravellers] = useState(true)
  const [showCompare, setShowCompare] = useState(false)
  const conditionsLoaded = Object.keys(conditions).length > 0
  const logos = useInsurersLogos()
  const coverageItems = ["Medical emergency", "Pre-existing Conditions"]
  const options = useSelector(state => state.options)
  const famRate = useSelector(state => state.famRate)

  const onSelectPreExisting = e => {
    dispatch(clearQuotes())
    dispatch(preExistingConditionAction(e.target.value === "include"))
    if (e.target.value === "include") {
      dispatch({
        type: ACTIONS.SET_OPTIONS,
        payload: { code: "include-pre-existing-coverage" },
      })
    } else {
      dispatch({
        type: ACTIONS.DELETE_OPTION,
        payload: { code: "include-pre-existing-coverage" },
      })
    }
    dispatch(
      insurerAvailAction(
        checkInsuerAvailability(
          coverage,
          deductible,
          allCoveragesDeductibles,
          e.target.value === "include",
          travellers,
          checkExtraSportCoverage(options)
        )
      )
    )
    dispatch(setUnavailDeductibles(coverage))
    dispatch(setUnavailCoverages(deductible))
    dispatch(makeQuoteAPICalls())
  }

  const compareInsurers = () => {
    if (!isCompareInsurers(compareInsurer)) return
    setShowCompare(true)
  }

  const onExtraSportCover = () => {
    dispatch(clearQuotes())
    dispatch(onChangeSportCover())
  }

  const onChangeSportCover = () => {
    return (dispatch, getState) => {
      const { coverage, deductible, preExisting, options } = getState()
      dispatch(
        insurerAvailAction(
          checkInsuerAvailability(
            coverage,
            deductible,
            allCoveragesDeductibles,
            preExisting,
            travellers,
            checkExtraSportCoverage(options)
          )
        )
      )
      dispatch(setUnavailCoverages())
      dispatch(setUnavailDeductibles())
      dispatch(makeQuoteAPICalls())
    }
  }

  const makeQuoteAPICalls = () => {
    return (dispatch, getState) => {
      const { insurerAvail, coverage, deductible, options } = getState()
      const makeQuoteAPICall = insurer => {
        const travelPlanPayload = setTravelPlanPayload(travelPlan)
        const travellersPayload = travellers
        const quotePayload = setPayloadForQuote(
          travelPlanPayload,
          travellersPayload,
          coverage,
          deductible,
          options,
          famRate
        )
        dispatch(quoteActionsToDispatch[insurer](quotePayload))
      }

      Object.keys(insurerAvail).forEach(insurer => {
        if (insurerAvail[insurer].avail) {
          makeQuoteAPICall(insurer)
        }
      })
    }
  }

  const setUnavailDeductibles = (
    all = allCoveragesDeductibles,
    above80 = travellerOver80(travellers)
  ) => {
    return (dispatch, getState) => {
      const { coverage, preExisting, options } = getState()
      const unAvailDeductibles = getUnavailableCoveragesOrDeductibles(
        "deductible",
        coverage,
        all,
        above80,
        preExisting,
        checkExtraSportCoverage(options)
      )
      dispatch(setUnavailableDeductibles(unAvailDeductibles))
    }
  }

  const setUnavailCoverages = (
    all = allCoveragesDeductibles,
    above80 = travellerOver80(travellers)
  ) => {
    return (dispatch, getState) => {
      const { deductible, preExisting, options } = getState()
      const unavailableCoverages = getUnavailableCoveragesOrDeductibles(
        "coverage",
        deductible,
        all,
        above80,
        preExisting,
        checkExtraSportCoverage(options)
      )
      dispatch(setUnavailableCoverages(unavailableCoverages))
    }
  }

  const onSelectCoverage = amount => {
    dispatch(setCoverage(amount))
    dispatch(
      insurerAvailAction(
        checkInsuerAvailability(
          amount,
          deductible,
          allCoveragesDeductibles,
          preExisting,
          travellers,
          checkExtraSportCoverage(options)
        )
      )
    )
    dispatch(setUnavailDeductibles())
    dispatch(makeQuoteAPICalls())
  }

  const onSelectDeductible = amount => {
    dispatch(setDeductible(amount))
    dispatch(
      insurerAvailAction(
        checkInsuerAvailability(
          coverage,
          amount,
          allCoveragesDeductibles,
          preExisting,
          travellers,
          checkExtraSportCoverage(options)
        )
      )
    )
    dispatch(setUnavailCoverages())
    dispatch(makeQuoteAPICalls())
  }

  useEffect(() => {
    getCurrentPath(location.pathname)
    dispatch(clearQuotes())
    setIsValidTravellers(validTravellers(travellers))

    let olderThan60 = false
    for (const traveller of travellers) {
      if (traveller.is60yrsOrOlder == "y") {
        olderThan60 = true
      }
    }
    // for people above 60 including 60, regardless of trip length,
    // default value: coverage amount of 100K with a deductible of 500
    if (olderThan60) {
      dispatch(setCoverage(100000))
      dispatch(setDeductible(500))
    } else {
      if (lessThanOneMonth(travelPlan.startDate, travelPlan.endDate)) {
        dispatch(setCoverage(50000))
        dispatch(setDeductible(0))
      } else {
        dispatch(setCoverage(100000))
        dispatch(setDeductible(500))
      }
    }
    if (!isValidTravellers) return
    return () => {
      dispatch(clearComparisonInsurer())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(conditions).length <= 0) return
    const all = coverageDeductibleCombo(conditions)
    dispatch(setAllData(all))
    setCoverages(getCoverages(all))
    setDeductibles(getDeductibles(all))
    dispatch(setUnavailCoverages(all))
    dispatch(setUnavailDeductibles(all))
    dispatch(
      insurerAvailAction(
        checkInsuerAvailability(
          coverage,
          deductible,
          all,
          preExisting,
          travellers,
          checkExtraSportCoverage(options)
        )
      )
    )
    dispatch(makeQuoteAPICalls())
  }, [conditions])

  useEffect(() => {
    dispatch(setQuotesLoaded(ifAllQuotesLoaded(quotes, insurerAvail)))
  }, [insurerAvail, quotes])

  const onGoBack = () => {
    navigate("/get-quote-travellers")
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - policies" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidTravellers ? (
          showCompare ? (
            <>
              <Title
                title={intl.formatMessage({ id: "plan.comparebenefits" })}
                fontColor="var(--clr-primary-300)"
              />
              <SectionCenter>
                <PlanComparison
                  onGoBack={() => {
                    dispatch(clearComparisonInsurer())
                    setShowCompare(false)
                  }}
                />
              </SectionCenter>
            </>
          ) : (
            <>
              <Title
                title={intl.formatMessage({ id: "policies.title" })}
                fontColor="var(--clr-primary-300)"
              />
              {!conditionsLoaded ? (
                <Spinner />
              ) : (
                <SectionCenter>
                  <div
                    css={css`
                      width: 90%;
                      display: grid;
                      grid-template-columns: 1fr;
                      row-gap: 1rem;
                      margin: 0 auto 4rem auto;

                      ${mq[0]} {
                        justify-content: center;
                        padding-left: 4rem;
                      }
                    `}
                  >
                    <CoveragesDeductibles
                      selected={coverage}
                      data={coverages}
                      onChange={onSelectCoverage}
                      unAvail={unavailableCoverages}
                      type="coverage"
                    />
                    <CoveragesDeductibles
                      selected={deductible}
                      data={deductibles}
                      onChange={onSelectDeductible}
                      unAvail={unavailableDeductibles}
                      type="deductible"
                    />
                    <PreExistingConditionToggle
                      onChange={onSelectPreExisting}
                    />
                    <TugoExtraOptions onChangeOpt={onExtraSportCover} />
                    {travellerOver80(travellers) && (
                      <p
                        css={css`
                          margin-left: auto;
                          margin-right: auto;
                          color: var(--clr-neutral-400);
                        `}
                      >
                        {intl.formatMessage({ id: "plan.notice1" })}
                      </p>
                    )}
                    {checkExtraSportCoverage(options) && (
                      <p
                        css={css`
                          margin-left: auto;
                          margin-right: auto;
                          color: var(--clr-neutral-400);
                        `}
                      >
                        {intl.formatMessage({ id: "plan.notice2" })}
                      </p>
                    )}
                    {allQuotesLoaded ? (
                      <>
                        {Object.keys(insurerAvail)
                          .sort((a, b) => {
                            if (
                              Object.keys(quotes[a]).price?.premium > 0 &&
                              Object.keys(quotes[b]).price?.premium > 0
                            ) {
                              return (
                                quotes[a].price.premium -
                                quotes[b].price.premium
                              )
                            }
                            if (insurerAvail[a].avail) return -1
                            return 0
                          })
                          .map(insurer => {
                            if (insurerAvail[insurer].avail) {
                              const plan = setPlanCardData(
                                insurer,
                                logos[insurer].fluid,
                                quotes[insurer].price?.premium,
                                insurer === "gms" || insurer === "tugo"
                                  ? coverageItems
                                  : preExisting
                                  ? coverageItems
                                  : coverageItems.filter(
                                      item =>
                                        !item
                                          .toLowerCase()
                                          .includes("pre-existing")
                                    ),
                                travelPlan.endDate.diff(
                                  travelPlan.startDate,
                                  "days"
                                ) + 1
                              )
                              return <PlanCard plan={plan} key={insurer} />
                            }
                            const notAvailPlan = setPlanCardData(
                              insurer,
                              logos[insurer].fluid,
                              null,
                              [],
                              travelPlan.endDate.diff(
                                travelPlan.startDate,
                                "days"
                              ) + 1,
                              insurerAvail[insurer].quoteNotAvailMessage
                            )
                            return (
                              <PlanCard
                                plan={notAvailPlan}
                                key={insurer}
                                notAvailable
                              />
                            )
                          })}
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <SecondaryButton
                      buttonColor="var(--clr-primary-300)"
                      textSize="0.875rem"
                      margin="0 4rem 0 0"
                      disabled={!isCompareInsurers(compareInsurer)}
                      disabledBorder="2px"
                      onClick={compareInsurers}
                    >
                      {intl.formatMessage({ id: "plan.comparebenefits" })}
                    </SecondaryButton>
                    <SecondaryButton
                      buttonColor="var(--clr-neutral-400)"
                      textSize="0.875rem"
                      margin="none"
                      onClick={onGoBack}
                    >
                      {intl.formatMessage({ id: "btn.goback" })}
                    </SecondaryButton>
                  </div>
                </SectionCenter>
              )}
            </>
          )
        ) : (
          <GoBack
            backToLink="/get-quote-travellers"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem2" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  apiCallStatus,
  compareInsurer,
  unavailableCoverages,
  unavailableDeductibles,
  insurerAvail,
}) => ({
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  apiCallStatus,
  compareInsurer,
  unavailableCoverages,
  unavailableDeductibles,
  insurerAvail,
})

export default connect(mapStateToProps, { getCurrentPath })(GetQuotePolicies)
